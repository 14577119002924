// Colors
$cdu-blue: rgb(29, 21, 66);
$white: #fff;
$black: rgb(51, 51, 51);
$green: rgb(20, 101, 112);
$yellow: rgb(215, 178, 56);
$grey: rgb(215, 218, 221);

// Specific colours
$body-background: rgb(239, 243, 246);
$menu-background: $cdu-blue;
$header-background: $white;
$heading-colour: $cdu-blue;
$subheading-colour: rgb(102, 116, 142);
$divider-colour: $grey;
$highlight-colour: $green;
$highlight2-colour: $yellow;
$dark-grey--text: rgb(102, 102, 102);
$error-background-colour: #ffdede;
$error-outline-colour: rgb(130, 15, 22);
$error-text-colour: rgb(130, 15, 22);

$access-list-admin-colour: $highlight-colour;

// Sizing
$header-height: 85px;

// Typography
$font-family-base: 'Open Sans', sans-serif;
$font-family-heading: 'myriadpro-light', 'myriad-pro', sans-serif;;
$font-family-body: $font-family-base;

// Specific admin form stuff
$row-width: 1000px;
$list-marker-width: 40px;
$list-marker-margin: 10px;
$form-height: 65px;

// Ugly stuff
$admin-layout-breakpoint: 1000px;
$max-width: 1400px;




// Old stuff
$gray: #f7f7f7;
$white: #fff;
$black: #000;
$blue: #3cb0fd; // hsla(204, 98%, 61%, 1)
$blue-button-hover: #3498db;
$gray80: #ccc;
$header-colour: #373277;
$border-gray: #e6e6e6;

$body__background: $gray;
$highlight--colour: $blue;


$white-base:            hsl(255, 255, 255);
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee



// Layout
$max-content-width:     1000px;

// Media queries breakpoints - I feel like this should be done on the component level
$screen-xs-min:         480px;
$screen-sm-min:         768px;
$screen-md-min:         992px;
$screen-lg-min:         1200px;

// Animations - Also should probably be done by component (or possibly as a mix-in)
$animation-swift-out:   0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s;

// Z-Index
$z-index-bottom: 0;
$z-index-normal: 50;
$z-index-top: 100;
