@import '../../styles/variables.scss';

$brand-color: #61dafb;

.container {
  margin: 0 auto;
  padding: 20px 0;
  max-width: $max-content-width;
}

.heading {
	color: $gray-dark;
	font-family: $font-family-heading;
	font-size: 3em;
	text-align: center;
}

.content {
	font-family: $font-family-body;
	text-align: center;
	font-size: 2em;
	color: black;
}



